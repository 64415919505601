import React from 'react';
import './main.scss';

function AboutHome() {
  return (
    <div className="about-home">
      <h1>About Page</h1>
      <hr />
      <p>Content is being updated, please check back in a day or two. Thanks.</p>
    </div>
  );
}

export default AboutHome;
