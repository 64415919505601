import React from 'react';
import BaseLayout from './pages/components/BaseLayout';

function App() {
  return (
    <BaseLayout />
  );
}

export default App;
