import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './topnavbar.scss';

function TopNavBar() {
  return (
    <div className="top-nav-bar top-banner">
      <Navbar className="navbar transparent navbar-inverse navbar-fixed-top navbar-dark" expand="lg" sticky="top">
        <Navbar.Brand href="#home">Michaels3DHub.com</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link className="text-white" href="/">Home</Nav.Link>
            <Nav.Link className="text-white" href="/about">About</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default TopNavBar;
