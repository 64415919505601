import React from 'react';
import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom';
import HomePage from '../home/HomePage';
import AboutHome from '../about/AboutHome';
import TopNavBar from './TopNavBar';
import Footer from './Footer';
import './baselayout.scss';

function BaseLayout() {
  return (
    <div className="base-layout">
      <div className="row">
        <div className="col-md-12">
          <Router>
            <TopNavBar />
            <br />
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route exact path="/home">
                <HomePage />
              </Route>
              <Route path="/about">
                <AboutHome />
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BaseLayout;
