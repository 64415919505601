import React from 'react';
import './main.scss';

function HomePage() {
  return (
    <div className="home-page">
      <h1>Michael's 3D Hub</h1>
      <hr />
      <p>We are updating our page, please check back in a day or two, thanks.</p>
    </div>
  );
}

export default HomePage;
